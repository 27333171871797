import { createQueryKeyStore } from '@lukemorales/query-key-factory'
import {
  GetAlertsQueryVariables,
  GetCampaignOrdersQueryVariables,
  GetCampaignQueryVariables,
  GetCardQueryVariables,
  GetCoachingBadgesQueryVariables,
  GetContactRequestByTokenQueryVariables,
  GetDraftedCardsQueryVariables,
  GetImagesQueryVariables,
  GetItemQueryVariables,
  GetItemsQueryVariables,
  GetLayoutQueryVariables,
  GetLayoutsQueryVariables,
  GetNotificationsQueryVariables,
  GetOrderQueryVariables,
  GetPaginatedInvitesQueryVariables,
  GetPaginatedRecipientsQueryVariables,
  GetPlanMoveQueryVariables,
  GetRemindersQueryVariables,
  GetSendableCardQueryVariables,
  GetSentCardsCountQueryVariables,
  GetSentCardsQueryVariables,
  GetSharedCampaignsQueryVariables,
  GetSharedContactsQueryVariables,
  GetSponsorQueryVariables,
  GetStickerCategoriesQueryVariables,
  GetStickersQueryVariables,
  GetUsersQueryVariables,
} from 'src/graphql/generated/graphql'
import * as graphql from 'src/graphql/generated/graphql'
import {
  CampaignStoreSubcategoriesQueryVariables,
  CampaignStoreSubcategoryQueryVariables,
  CheckoutQueryVariables,
  ProductVariantQueryVariables,
  ProductVariantsQueryVariables,
  SaleorOrderQueryVariables,
} from 'src/saleor_graphql/generated/graphql'
import {
  getAccount,
  getAccounCreatedContents,
  getAffiliateCountries,
  getAlerts,
  getByDesignLoginLink,
  getCampaign,
  getCampaignOrders,
  getCampaignStoreSubcategories,
  getCampaignStoreSubcategory,
  getCard,
  getCheckout,
  getCoachingBadges,
  getCoachingDashboardMarketingContent,
  getContactRequestByToken,
  getContactRequests,
  getCreditsBalance,
  getDetailedCountries,
  getDraftedCards,
  getFlags,
  getFonts,
  getImages,
  getItem,
  getItemCategories,
  getItems,
  getLayout,
  getLayouts,
  getLeaderBoardMarketingContent,
  getLeaderBoards,
  getMarketingBanners,
  getMarketingContent,
  getNotificationCount,
  getNotifications,
  getOrder,
  getPaginatedInvites,
  getPaginatedRecipients,
  getPlanMove,
  getPlans,
  getProductVariant,
  getProductVariants,
  getPromptingsCoachDiscounts,
  getPromptingsEventDates,
  getReceivedPendingMembershipInvites,
  getReminders,
  getSaleorCustomer,
  getSaleorOrder,
  getSendableCard,
  getSentCards,
  getSentCardsCount,
  getSharedCampaigns,
  getSharedContacts,
  getStickerCategories,
  getStickers,
  getUsers,
} from './queries/fetching'
import * as fetching from './queries/fetching'

export const queryKeyStore = createQueryKeyStore({
  account: {
    detail: {
      queryFn: () => getAccount(),
      queryKey: null,
    },
  },
  countries: {
    list: {
      queryFn: () => getDetailedCountries(),
      queryKey: null,
    },
  },
  affiliate_countries: {
    list: {
      queryFn: () => getAffiliateCountries(),
      queryKey: null,
    },
  },
  daily_cap: {
    heartfelt: {
      queryFn: fetching.getAvailableDailyHeartfelt,
      queryKey: null,
    },
    unlimited_heartfelt: {
      queryFn: fetching.getAvailableUnlimitedDailyHeartfelt,
      queryKey: null,
    },
    add_payment_settings: {
      queryFn: fetching.getAddPaymentSettingsDailyCap,
      queryKey: null,
    },
  },
  bydesign_login_link: {
    detail: {
      queryFn: () => getByDesignLoginLink(),
      queryKey: null,
    },
  },
  alerts: {
    infinite: (variables: GetAlertsQueryVariables) => ({
      queryFn: () => getAlerts(variables),
      queryKey: [
        {
          filters: {
            reminders: variables.reminderFilters,
            notifications: variables.notificationFilters,
          },
        },
      ],
    }),
  },
  card: {
    detail: (variables: GetCardQueryVariables) => ({
      queryFn: () => getCard({ id: variables.id }),
      queryKey: [variables.id],
    }),
  },
  sendableCard: {
    detail: (variables: GetSendableCardQueryVariables) => ({
      queryFn: () => getSendableCard({ id: variables.id }),
      queryKey: [variables.id],
    }),
  },
  sent_card: {
    list_paginated: (variables: GetSentCardsQueryVariables) => ({
      queryFn: () => getSentCards(variables),
      queryKey: [{ filters: variables }],
    }),
    count: (variables: GetSentCardsCountQueryVariables) => ({
      queryFn: () => getSentCardsCount(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  drafted_card: {
    list: (variables: GetDraftedCardsQueryVariables) => ({
      queryFn: () => getDraftedCards(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  itemCategory: {
    list: {
      queryFn: () => getItemCategories(),
      queryKey: null,
    },
  },
  item: {
    list: (variables: GetItemsQueryVariables) => ({
      queryFn: () => getItems(variables),
      queryKey: [
        {
          filters: {
            categories: variables?.category,
            search: variables?.search,
          },
        },
      ],
    }),
    detail: (variables: GetItemQueryVariables) => ({
      queryFn: () => getItem(variables),
      queryKey: [variables],
    }),
  },
  leader_board: {
    list: {
      queryFn: () => getLeaderBoards(),
      queryKey: null,
    },
  },
  campaign: {
    detail: (variables: GetCampaignQueryVariables) => ({
      queryFn: () => getCampaign(variables),
      queryKey: [variables.id],
    }),
  },
  infinite_campaign_order: {
    list: (variables: GetCampaignOrdersQueryVariables) => ({
      queryFn: () => getCampaignOrders(variables),
      queryKey: [variables.id],
    }),
  },
  paginated_invites: {
    list: (variables: GetPaginatedInvitesQueryVariables) => ({
      queryFn: () => getPaginatedInvites(variables),
      queryKey: [{ filters: variables }],
    }),
    infinite: (variables: GetPaginatedInvitesQueryVariables) => ({
      queryFn: () => getPaginatedInvites(variables),
      queryKey: [''],
    }),
  },
  production_info: {
    detail: (variables: graphql.GetProductionInfoQueryVariables) => ({
      queryFn: () => fetching.getProductionInfo(variables),
      queryKey: [variables],
    }),
  },
  recipients: {
    list: (variables: GetPaginatedRecipientsQueryVariables) => ({
      queryFn: () => getPaginatedRecipients(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  flags: {
    list: {
      queryFn: () => getFlags(),
      queryKey: null,
    },
  },
  marketing_content: {
    list: {
      queryFn: () => getMarketingContent(),
      queryKey: null,
    },
    affiliate_join: {
      queryFn: fetching.getAffiliateJoinMarketingContent,
      queryKey: null,
    },
    leader_board: {
      queryFn: () => getLeaderBoardMarketingContent(),
      queryKey: null,
    },
    coaching_dashboard: {
      queryFn: () => getCoachingDashboardMarketingContent(),
      queryKey: null,
    },
    credit_card_manager: {
      queryFn: fetching.getCreditCardManagerMarketingContent,
      queryKey: null,
    },
    contact_request_mkt: {
      queryFn: fetching.getContactRequesMarketingContent,
      queryKey: null,
    },
    submit_requested_contact: {
      queryFn: fetching.getSubmitRequestedContactMarketingContent,
      queryKey: null,
    },
    promptings_event_dates: {
      queryFn: () => getPromptingsEventDates(),
      queryKey: null,
    },
    gratitude_day: {
      queryFn: fetching.getGratitudeDayMarketingContent,
      queryKey: null,
    },
    account_created: {
      queryFn: () => getAccounCreatedContents(),
      queryKey: null,
    },
  },
  marketing_banner: {
    list: {
      queryFn: () => getMarketingBanners(),
      queryKey: null,
    },
  },
  layout: {
    list: (variables: GetLayoutsQueryVariables) => ({
      queryFn: () => getLayouts(variables),
      queryKey: [{ filters: variables }],
    }),
    infinite: (variables: GetLayoutsQueryVariables) => ({
      queryFn: () => getLayouts(variables),
      queryKey: [{ filters: variables }],
    }),
    detail: (variables: GetLayoutQueryVariables) => ({
      queryFn: () => getLayout(variables),
      queryKey: [variables.id],
    }),
  },
  images: {
    list: (variables: GetImagesQueryVariables) => ({
      queryFn: () => getImages(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  credits_balance: {
    detail: {
      queryFn: () => getCreditsBalance(),
      queryKey: null,
    },
  },
  order: {
    detail: (variables: GetOrderQueryVariables) => ({
      queryFn: () => getOrder(variables),
      queryKey: [variables.id],
    }),
  },
  font: {
    list: {
      queryFn: () => getFonts(),
      queryKey: null,
    },
  },
  coaching_badges: {
    list: (variables: GetCoachingBadgesQueryVariables) => ({
      queryFn: () => getCoachingBadges(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  plan: {
    list: {
      queryFn: () => getPlans(),
      queryKey: null,
    },
  },
  plan_move: {
    detail: (variables: GetPlanMoveQueryVariables) => ({
      queryFn: () => getPlanMove(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  promptings_coach_discounts: {
    detail: {
      queryFn: () => getPromptingsCoachDiscounts(),
      queryKey: null,
    },
  },
  notification: {
    list: (variables: GetNotificationsQueryVariables) => ({
      queryFn: () => getNotifications(variables),
      queryKey: [{ filters: variables }],
    }),
    count: {
      queryFn: () => getNotificationCount(),
      queryKey: null,
    },
  },
  received_pending_membership_invites: {
    detail: {
      queryFn: () => getReceivedPendingMembershipInvites(),
      queryKey: null,
    },
  },
  reminders: {
    detail: (variables: GetRemindersQueryVariables) => ({
      queryFn: () => getReminders(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  shared_campaigns: {
    detail: (variables: GetSharedCampaignsQueryVariables) => ({
      queryFn: () => getSharedCampaigns(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  shared_contacts: {
    detail: (variables: GetSharedContactsQueryVariables) => ({
      queryFn: () => getSharedContacts(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  contact_requests: {
    list: {
      queryFn: () => getContactRequests(),
      queryKey: null,
    },
    by_token: (variables: GetContactRequestByTokenQueryVariables) => ({
      queryFn: () => getContactRequestByToken(variables),
      queryKey: [variables.token],
    }),
  },
  checkout: {
    detail: (variables: CheckoutQueryVariables) => ({
      queryFn: () => getCheckout(variables),
      queryKey: [variables.id],
    }),
  },
  saleor_customer: {
    detail: {
      queryKey: null,
      queryFn: () => getSaleorCustomer(),
    },
  },
  saleor_product_variant: {
    detail: (variables: ProductVariantQueryVariables) => ({
      queryFn: () => getProductVariant(variables),
      queryKey: [variables],
    }),
  },
  saleor_infinite_product_variants: {
    list: (variables: ProductVariantsQueryVariables) => ({
      queryFn: () => getProductVariants(variables),
      queryKey: [{ filters: variables.filter, ids: variables.ids }],
    }),
  },
  saleor_order: {
    detail: (variables: SaleorOrderQueryVariables) => ({
      queryFn: () => getSaleorOrder(variables),
      queryKey: [variables.id],
    }),
  },
  sticker_category: {
    infinite: (variables: GetStickerCategoriesQueryVariables) => ({
      queryFn: () => getStickerCategories(variables),
      queryKey: ['new_editor_sticker_categories'],
    }),
  },
  infinite_campaign_store_subcategories: {
    list: (variables: CampaignStoreSubcategoriesQueryVariables) => ({
      queryFn: () => getCampaignStoreSubcategories(variables),
      queryKey: [variables],
    }),
  },
  infinite_campaign_store_subcategory_products: {
    list: (variables: CampaignStoreSubcategoryQueryVariables) => ({
      queryFn: () => getCampaignStoreSubcategory(variables),
      queryKey: [variables],
    }),
  },
  lines: {
    list: (filters: { checkoutId: string }) => ({
      queryFn: () => getCheckout({ id: filters.checkoutId }),
      queryKey: [{ filters }],
    }),
  },
  infinite_stickers: {
    list: (variables: GetStickersQueryVariables) => ({
      queryFn: () => getStickers(variables),
      queryKey: ['new_editor_stickers', { filters: variables.filters }],
    }),
  },
  sponsor: {
    detail: (variables: GetSponsorQueryVariables) => ({
      queryFn: () => fetching.getSponsor(variables),
      queryKey: [variables],
    }),
  },
  user: {
    list: (variables: GetUsersQueryVariables) => ({
      queryFn: () => getUsers(variables),
      queryKey: [{ filters: variables }],
    }),
  },
  user_card_tokens: {
    detail: {
      queryKey: null,
      queryFn: fetching.getUserCardTokens,
    },
  },
})
