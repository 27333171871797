import {
  CardFragment,
  CardType,
  ColorFragment,
  FontFragment,
  LayoutFragment,
  Maybe,
  StickerFragment,
  TextAlignment,
  UserImageFragment,
} from 'src/graphql/generated/graphql'
import {
  EditorElement,
  EditorFullBleed,
  EditorImage,
  EditorPanel,
  EditorSticker,
  RawText,
} from 'src/editor/EditorCard'
import {
  Card,
  Element,
  FullBleed,
  Panel,
  Paragraphs,
  TextAlign,
} from '@sendoutcards/editor/dist/types/card'
import { Color, Image, Layout } from '@sendoutcards/editor/dist/types/primary'
import { TextFragment } from './types'

export const isInsideRightPanel = (
  cardType: CardType,
  fullBleedLocation: number,
  panelLocation: number,
) => {
  switch (cardType) {
    case CardType.Postcard:
    case CardType.Flatcard:
      return fullBleedLocation === 1 && panelLocation === 0
    case CardType.ThreePanel:
      return fullBleedLocation === 2 && panelLocation === 0
    case CardType.TwoPanel:
    case CardType.TwoPanelBig:
    default:
      return fullBleedLocation === 1 && panelLocation === 1
  }
}

export const updateFullBleed = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  update: (fullBleed: EditorFullBleed) => EditorFullBleed,
) =>
  fullBleeds.map((fullBleed, index) =>
    index === fullBleedIndex ? update(fullBleed) : fullBleed,
  )

export const updatePanel = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number,
  update: (panel: EditorPanel) => EditorPanel,
) =>
  updateFullBleed(fullBleeds, fullBleedIndex, fullBleed => ({
    ...fullBleed,
    panels: fullBleed.panels.map((panel, index) =>
      index === panelIndex ? update(panel) : panel,
    ),
  }))

export const updateElement = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number | undefined,
  elementIndex: number,
  update: (element: EditorElement, panel?: EditorPanel) => EditorElement,
) =>
  typeof panelIndex === 'number'
    ? updatePanel(fullBleeds, fullBleedIndex, panelIndex, panel => ({
        ...panel,
        elements: panel.elements.map((element, index) =>
          index === elementIndex ? update(element, panel) : element,
        ),
      }))
    : updateFullBleed(fullBleeds, fullBleedIndex, fullBleed => ({
        ...fullBleed,
        backgroundElement: fullBleed.backgroundElement
          ? update(fullBleed.backgroundElement)
          : null,
      }))

export const updateElementImage = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number | undefined,
  elementIndex: number,
  update: (image: EditorImage) => EditorImage,
) =>
  updateElement(
    fullBleeds,
    fullBleedIndex,
    panelIndex,
    elementIndex,
    element => ({
      ...element,
      image: element.image ? update(element.image) : null,
    }),
  )

export const updateElementSticker = (
  fullBleeds: EditorFullBleed[],
  fullBleedIndex: number,
  panelIndex: number | undefined,
  elementIndex: number,
  update: (sticker: EditorSticker) => EditorSticker,
) =>
  updateElement(
    fullBleeds,
    fullBleedIndex,
    panelIndex,
    elementIndex,
    element => ({
      ...element,
      sticker: element.sticker ? update(element.sticker) : null,
    }),
  )

export const newEditorColor = ({ red, green, blue }: ColorFragment): Color => ({
  r: red,
  g: green,
  b: blue,
})

export const userImageFragmentToImageType = (
  image: UserImageFragment,
): Image => {
  return {
    width: image.width,
    height: image.height,
    id: image.id,
    url: image.url,
    thumbnailUrl: image.url, // TODO smallThumb is too low resolution, can we get something in between smallThumb & url?
    isSticker: false,
  }
}

export const stickerFragmentToImageType = (sticker: StickerFragment): Image => {
  return {
    width: sticker.image.width,
    height: sticker.image.height,
    id: sticker.image.id,
    url: sticker.image.url,
    thumbnailUrl: sticker.image.url, // TODO smallThumb is too low resolution, can we get something in between smallThumb & url?
    isSticker: true,
  }
}

// Takes in a SOC element and maps it to a New Card Element
const newEditorElement = (fonts: FontFragment[]) => (
  element: EditorElement,
): Element => {
  const elementCopy = { ...element }

  function newElementText(text: RawText): Paragraphs {
    if (!text || text.paragraphs.length === 0) {
      return { paragraphs: [] }
    }

    return {
      paragraphs: text.paragraphs.map(paragraph => ({
        type: 'paragraph',
        alignment: paragraph.alignment,
        content: paragraph.textFragments.map(fragment => ({
          text: fragment.text,
          attributes: {
            isReplacement: fragment.isReplacement,
            size: fragment.fontSize,
            fontFamily:
              fonts.find(font => font.id === fragment.font.id)?.fontName ||
              'HelveticaV2',
            color: {
              r: fragment.textColor.red,
              g: fragment.textColor.green,
              b: fragment.textColor.blue,
            },
          },
        })),
      })),
    }
  }

  const elementText = element.rawText ? newElementText(element.rawText) : null

  return {
    z: elementCopy.z,
    rotation: elementCopy.rotation,
    locked: elementCopy.locked,
    x: elementCopy.x,
    y: elementCopy.y,
    width: elementCopy.width,
    height: elementCopy.height,
    draggable: element.draggable ?? false,
    image: element.image
      ? {
          ...element.image,
          image: element.image.image
            ? {
                ...element.image.image,
                thumbnailUrl: element.image.image.smallThumb,
              }
            : null,
        }
      : element.sticker
      ? {
          ...element.sticker,
          image: element.sticker.sticker
            ? {
                ...element.sticker.sticker,
              }
            : null,
          filter: null,
        }
      : null,
    text: elementText ?? null,
  }
}

export const newEditorPanel = (fonts: FontFragment[]) => (
  panel: EditorPanel,
): Panel => {
  const panelCopy = { ...panel }

  return {
    name: panelCopy.name,
    isLocked: panelCopy.isLocked,
    x: panelCopy.x,
    y: panelCopy.y,
    width: panelCopy.width,
    height: panelCopy.height,
    backgroundColor:
      panel.backgroundColor && newEditorColor(panel.backgroundColor),
    elements: panel.elements.map(newEditorElement(fonts)),
  }
}
export const newEditorCard = (
  card: CardFragment,
  fullBleeds: EditorFullBleed[],
  fonts: FontFragment[],
  variationID: string | null,
): Card => {
  // Spreading the card object into an implicit return "bloats" the "Sendogo"
  // Card with uneccessary properties that exist on the "SOC" Card object
  const cardCopy = { ...card }
  return {
    backPanelLocation: cardCopy.backPanelLocation,
    type: cardCopy.type,
    orientation: cardCopy.isHorizontal ? 'LANDSCAPE' : 'PORTRAIT',
    fullBleeds: fullBleeds
      .filter(fb => fb.panels.every(pn => pn.name !== ''))
      .map(fullBleed => ({
        ...fullBleed,
        backgroundElement:
          fullBleed.backgroundElement &&
          newEditorElement(fonts)(fullBleed.backgroundElement),
        panels: fullBleed.panels.map(newEditorPanel(fonts)),
      }))
      .sort((a, b) => {
        const aFullPanel = a.backgroundElement ? 1 : 0
        const bFullPanel = b.backgroundElement ? 1 : 0

        return aFullPanel - bFullPanel
      }),
    variationState:
      cardCopy.detailedSendableCard?.variations && variationID
        ? {
            variations: [
              {
                id: cardCopy.detailedSendableCard.id,
                color: cardCopy.detailedSendableCard.variationColor,
                frontImage: {
                  id: cardCopy.detailedSendableCard.frontImage.id,
                  height: cardCopy.detailedSendableCard.frontImage.height,
                  width: cardCopy.detailedSendableCard.frontImage.width,
                  url: cardCopy.detailedSendableCard.frontImage.url,
                  thumbnailUrl:
                    cardCopy.detailedSendableCard.frontImage.smallThumb,
                },
                insideRightImage: cardCopy.detailedSendableCard.insideRightImage
                  ? {
                      url: cardCopy.detailedSendableCard.insideRightImage.url,
                      height:
                        cardCopy.detailedSendableCard.insideRightImage.height,
                      width:
                        cardCopy.detailedSendableCard.insideRightImage.width,
                      thumbnailUrl:
                        cardCopy.detailedSendableCard.insideRightImage
                          .smallThumb,
                      id: cardCopy.detailedSendableCard.insideRightImage.id,
                    }
                  : undefined,
              },
              ...cardCopy.detailedSendableCard.variations.map(v => ({
                id: v.id,
                color: v.variationColor,
                frontImage: {
                  id: v.frontImage.id,
                  height: v.frontImage.height,
                  width: v.frontImage.width,
                  url: v.frontImage.url,
                  thumbnailUrl: v.frontImage.smallThumb,
                },
                insideRightImage: v.insideRightImage
                  ? {
                      id: v.insideRightImage?.id,
                      height: v.insideRightImage?.height,
                      width: v.insideRightImage?.width,
                      url: v.insideRightImage?.url,
                      thumbnailUrl: v.insideRightImage.smallThumb,
                    }
                  : undefined,
              })),
            ],
            selectedVariationID: variationID,
          }
        : undefined,
  }
}

export const newEditorLayout = (fonts: FontFragment[]) => (
  layout: LayoutFragment,
): Layout => ({
  ...layout,
  panel: newEditorPanel(fonts)({
    ...layout.panel,
    backgroundColor: layout.panel.backgroundColor ?? null,
    x: 0,
    y: 0,
    elements: layout.panel.elements.map(EditorElement),
  }),
})

const editorColor = ({ r, g, b }: Color): ColorFragment => ({
  __typename: 'Color',
  red: r,
  green: g,
  blue: b,
})

const editorFontId = (
  signatureFontFamily: string | undefined,
  fonts: FontFragment[],
  fontFamily: string,
): string => {
  const signatureFont = fonts.find(
    font => font.fontName === signatureFontFamily,
  )?.id
  const regularFont = fonts.find(font => font.fontName === fontFamily)?.id
  const defaultFont = fonts.find(font => font.fontName === 'HelveticaV2')?.id

  if (signatureFontFamily && signatureFont) {
    return (
      fonts.find(font => font.fontName === signatureFontFamily)?.id ??
      fonts[0].id
    )
  } else if (regularFont) {
    return regularFont
  } else if (defaultFont) {
    return defaultFont
  } else {
    return fonts[0].id
  }
}

const editorElement = (
  element: Element,
  fonts: FontFragment[],
): EditorElement => {
  const isNumberOnly = (value: string) => {
    const regex = /^\d+$/
    return regex.test(value)
  }
  const elementSticker: Maybe<EditorSticker> =
    element.image && element.image.image && isNumberOnly(element.image.image.id)
      ? {
          ...element.image,
          sticker: element.image.image && {
            ...element.image.image,
            __typename: 'EditorImageType',
          },
        }
      : null
  const elementImage: Maybe<EditorImage> =
    element.image &&
    element.image.image &&
    !isNumberOnly(element.image.image.id)
      ? {
          ...element.image,
          image: element.image.image && {
            ...element.image.image,
            smallThumb: element.image.image.url,
            __typename: 'Image',
          },
        }
      : null

  // For every paragraph we need to create an "empty fragment"
  // Then for each content piece in that paragraph we would add after the fact

  const elementText = element.text?.paragraphs.reduce(
    (fragment: TextFragment[], paragraph) => {
      const paragraphFragment: TextFragment = {
        fontSize: 16,
        signatureFontId: undefined,
        text: undefined,
        color: editorColor({ r: 0, g: 0, b: 0 }),
        fontId: editorFontId(undefined, fonts, 'HelveticaV2'), // TODO - NEW_TEXT_EDITOR possible issues?
        textAlign:
          paragraph.alignment.toUpperCase() === 'LEFT'
            ? TextAlignment.Left
            : paragraph.alignment.toUpperCase() === 'CENTER'
            ? TextAlignment.Center
            : paragraph.alignment.toUpperCase() === 'RIGHT'
            ? TextAlignment.Right
            : undefined,
      }
      const contentFragments =
        paragraph.content?.map(content => ({
          fontSize: content.attributes.size,
          isReplacement: content.attributes.isReplacement,
          text: content.attributes.signatureFontFamily ? 'æ' : content.text,
          color: editorColor(content.attributes.color),
          fontId: editorFontId(
            content.attributes.signatureFontFamily,
            fonts,
            content.attributes.fontFamily ?? 'HelveticaV2',
          ),
        })) ?? []

      const updated = [...fragment, paragraphFragment, ...contentFragments]

      return updated
    },
    [],
  )

  const aligmentMap: { [key in TextAlign]: TextAlignment } = {
    CENTER: TextAlignment.Center,
    LEFT: TextAlignment.Left,
    RIGHT: TextAlignment.Right,
  }

  const elementRawText: RawText = element.text
    ? {
        __typename: 'ElementText',
        paragraphs: element.text.paragraphs.map(paragraph => ({
          __typename: 'Paragraph',
          alignment: aligmentMap[paragraph.alignment],
          textFragments: paragraph.content
            ? paragraph.content.map(content => ({
                __typename: 'TextFragment',
                text: content.text ?? '',
                fontSize: 16,
                isReplacement: true,
                textColor: {
                  __typename: 'Color',
                  red: content.attributes.color.r,
                  green: content.attributes.color.g,
                  blue: content.attributes.color.b,
                },
                font: {
                  __typename: 'Font',
                  id: editorFontId(
                    content.attributes.signatureFontFamily,
                    fonts,
                    content.attributes.fontFamily ?? 'HelveticaV2',
                  ),
                  fontName: content.attributes.fontFamily ?? 'HelveticaV2',
                },
              }))
            : [],
        })),
      }
    : null

  return {
    __typename: 'Element',
    ...element,
    draggable: element.draggable ?? false,
    sticker: elementSticker,
    image: elementImage,
    text: elementText ?? null,
    rawText: elementRawText,
  }
}

export const editorPanel = (
  panel: Panel,
  fonts: FontFragment[],
): EditorPanel => ({
  ...panel,
  backgroundColor: panel.backgroundColor && editorColor(panel.backgroundColor),
  elements: panel.elements
    .filter(element => element.image || element.text)
    .map(element => editorElement(element, fonts)),
})

export const editorFullBleed = (fonts: FontFragment[]) => (
  fullBleed: FullBleed,
): EditorFullBleed => {
  return {
    ...fullBleed,
    panels: fullBleed.panels.map(panel => editorPanel(panel, fonts)),
    backgroundElement:
      fullBleed.backgroundElement &&
      editorElement(fullBleed.backgroundElement, fonts),
  }
}
