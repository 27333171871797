import { TextFragment } from 'src/editor/types'

import { paragraphs } from '../../redux/selectors/text'
import { IndexedTextFragment } from './types'
import { signatureCharacter } from './ElementText'
import { ParagraphInput } from 'src/graphql/generated/graphql'

type ReducedFragment = IndexedTextFragment & { isPlainText?: boolean | '' }

const reducedFragments = (
  fragments: IndexedTextFragment[],
): ReducedFragment[] => {
  return fragments.reduce((fonts: ReducedFragment[], fragment) => {
    const lastFont = fonts[fonts.length - 1]
    const isPlainText = fragment.text?.length === 1
    if (
      lastFont &&
      isPlainText &&
      lastFont.isPlainText &&
      lastFont.fontId === fragment.fontId &&
      lastFont.signatureFontId === fragment.signatureFontId &&
      lastFont.fontSize === fragment.fontSize &&
      lastFont.color.red === fragment.color.red &&
      lastFont.color.green === fragment.color.green &&
      lastFont.color.blue === fragment.color.blue
    ) {
      return [
        // tslint:disable-next-line
        ...fonts.splice(0, fonts.length - 1),
        {
          ...lastFont,
          text: (lastFont.text ?? '') + (fragment.text ?? ''),
        },
      ]
    } else {
      return [
        ...fonts,
        {
          ...fragment,
          isPlainText,
        },
      ]
    }
  }, [])
}

const paragraphInputs = (fragments: TextFragment[]): ParagraphInput[] => {
  return paragraphs(fragments).map(paragraph => ({
    textFragments: reducedFragments(paragraph.fragments).map(
      reducedFragment => ({
        text: reducedFragment.signatureFontId
          ? signatureCharacter
          : reducedFragment.text ?? '',
        textColor: {
          red: reducedFragment.color.red ?? 0,
          green: reducedFragment.color.green ?? 0,
          blue: reducedFragment.color.blue ?? 0,
        },
        font: reducedFragment.signatureFontId ?? reducedFragment.fontId,
        fontSize: reducedFragment.fontSize,
        // isReplacement:
        //   !reducedFragment.isPlainText && !reducedFragment.signatureFontId,
        isReplacement: reducedFragment.isReplacement ?? false,
      }),
    ),
    alignment: paragraph.textAlign,
  }))
}

export default paragraphInputs
