import ElementText from 'src/editor/text/ElementText'
import { TextFragment } from 'src/editor/types'
import {
  CardFragment,
  ColorFragment,
  EditorImageFragment,
  ElementFragment,
  ElementPictureFragment,
  ElementStickerFragment,
  ImageFragment,
  Maybe,
  PanelFragment,
} from 'src/graphql/generated/graphql'

export type Position = { x: number; y: number }

export type EditorImage = {
  image?: Maybe<ImageFragment>
  filter: Maybe<{ name: string }>
  position: Position
  scale: number
  filterUrl?: string
}

export type EditorSticker = {
  sticker?: Maybe<EditorImageFragment>
  position: Position
  scale: number
}

const EditorImage = (elementImage: ElementPictureFragment): EditorImage => ({
  image: elementImage.image,
  filter: elementImage.filter ? { name: elementImage.filter.name } : null,
  position: elementImage.position,
  scale: elementImage.scale,
})

const EditorSticker = (
  elementSticker: ElementStickerFragment,
): EditorSticker => ({
  sticker: elementSticker.sticker,
  position: elementSticker.position,
  scale: elementSticker.scale,
})

export type RawText = ElementFragment['text']
export type EditorElement = Omit<
  ElementFragment,
  'sticker' | 'image' | 'text'
> & {
  sticker: Maybe<EditorSticker>
  image: Maybe<EditorImage>
  text: Maybe<TextFragment[]>
  rawText: Maybe<RawText>
}

export const EditorElement = (element: ElementFragment): EditorElement => ({
  ...element,
  sticker: element.sticker ? EditorSticker(element.sticker) : null,
  image: element.image ? EditorImage(element.image) : null,
  text: element.text ? ElementText(element.text) : null,
  rawText: element.text ?? null,
})

export type EditorPanel = {
  backgroundColor: Maybe<ColorFragment>
  name: string
  isLocked: boolean
  x: number
  y: number
  width: number
  height: number
  elements: EditorElement[]
  previewUrl?: string
}

export type EditorFullBleed = {
  name: string
  width: number
  height: number
  panels: EditorPanel[]
  backgroundElement: Maybe<EditorElement>
}

const simpleFullBleed = (panel: PanelFragment): EditorFullBleed => ({
  name: panel.isFullBleed ? 'FBBackground' : panel.name,
  width: panel.width,
  height: panel.height,
  panels: [
    {
      backgroundColor: panel.backgroundColor ?? null,
      name: panel.isFullBleed ? 'FBBackground' : panel.name,
      isLocked: panel.isLocked,
      x: 0,
      y: 0,
      width: 1,
      height: 1,
      elements: panel.elements.map(EditorElement),
    },
  ],
  backgroundElement: null,
})

const insideFullBleed = (
  panels: PanelFragment[],
  fullBleedPanel: PanelFragment | undefined,
  isHorizontal: boolean,
): EditorFullBleed => {
  const panelWidth = panels[0].width
  const panelHeight = panels[0].height
  const width = isHorizontal ? panelWidth : panelWidth * panels.length
  const height = isHorizontal ? panelHeight * panels.length : panelHeight
  return {
    name: 'Inside',
    width,
    height,
    panels: panels.map((panel, index) => ({
      previewUrl: panel.previewUrl,
      backgroundColor: panel.backgroundColor ?? null,
      name: panel.name,
      isLocked: panel.isLocked,
      x: isHorizontal ? 0 : index / panels.length,
      y: isHorizontal ? index / panels.length : 0,
      width: isHorizontal ? 1 : 1 / panels.length,
      height: isHorizontal ? 1 / panels.length : 1,
      elements: panel.elements.map(EditorElement),
    })),
    backgroundElement: fullBleedPanel?.elements.length
      ? EditorElement(fullBleedPanel.elements[0])
      : {
          __typename: 'Element',
          x: 0,
          y: 0,
          z: 0,
          rotation: 0,
          width: 1,
          height: 1,
          locked: false,
          image: {
            image: null,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            filter: null,
          },
          sticker: null,
          text: null,
          rawText: null,
          draggable: null,
        },
  }
}

export const factoryFullBleeds = (card: CardFragment) => {
  const panels = card.panels.filter(panel => !panel.isFullBleed)
  const insideFullBleedPanel = card.panels.find(
    panel => panel.isFullBleed && panel.location === 1,
  )
  switch (card.type) {
    case 'POSTCARD':
    case 'FLATCARD':
      return panels.map(simpleFullBleed)
    case 'TWO_PANEL':
    case 'TWO_PANEL_BIG':
      return [
        simpleFullBleed(panels[0]),
        insideFullBleed(
          panels.slice(1, 3),
          insideFullBleedPanel,
          card.isHorizontal,
        ),
        simpleFullBleed(panels[3]),
      ]
    case 'THREE_PANEL':
      return [
        simpleFullBleed(panels[0]),
        insideFullBleed(
          panels.slice(1, 4),
          insideFullBleedPanel,
          card.isHorizontal,
        ),
        simpleFullBleed(panels[4]),
        simpleFullBleed(panels[5]),
      ]
    default:
      return []
  }
}

export const factoryFBForNewCardEditor = (card: CardFragment) => {
  return card.panels.map(simpleFullBleed).sort((a, b) => {
    const aFullPanel = a.name === 'FBBackground' ? 1 : 0
    const bFullPanel = b.name === 'FBBackground' ? 1 : 0

    return aFullPanel - bFullPanel
  })
}
